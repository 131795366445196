/*
* opacity.scss
* 14-11-2022 - Jelmer Jellema - Spin in het Web B.V.
* add class opacity-0 - opactity-100 in steps of ten
*/

@use "sass:math";

@for $op from 0 through 10 {
  .opacity-#{$op * 10} {
    opacity: math.div($op, 10);
  }
}

